@import url('https://cdn.syncfusion.com/ej2/material.css');

.barBackgroud{
  background-color: cadetblue;
}
.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

.formCard {
  width: 100%;
  max-width: 320px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;

}


.divCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
}
h1{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 24px;
  font-weight: 900;
}

h1 {
  font-size: 36px;
  font-weight: 900;
}
.headingFont{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
}
.lableFont{
  font-family: Arial, Helvetica, sans-serif;
}
h2{
    font-size: 26px;
    font-weight: 800;
}

h3 {
  font-size: 16px;
  font-weight: 800;
}
form {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  flex-grow: 1;
  padding-bottom: 1rem;
}

#dataTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#dataTable td,
#dataTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

#dataTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#dataTable tr:hover {
  background-color: #ddd;
}

#dataTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

a,
a:visited {
  color: #fff;
}

input[type="text"],
input[type="password"],
select,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border-width: thin;
  width: 100%;
}

label{
  
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
}


button {
  padding: 0.5rem;
  background-color: cadetblue;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  border-radius: 0.3rem;
  margin-bottom: .5rem;
  margin-top: .5rem;
}
.iconDanger{
  color: red;
}

button:hover{
  background-color: #000;
  color:#fff;
}
.statusApproved{
  background-color: #008000;
}
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -2px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}



#cancelBtn {
  background-color: crimson;
}

.pageLayout {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* px-10 py-5 h-4/5 */
}



@media screen and (max-width:800px) {
  .sidebar {
    z-index: 10000000;
  }
    .pageLayout {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 5rem;
      padding-bottom: 3rem;
      /* px-10 py-5 h-4/5 */
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}